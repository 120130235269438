export const copy = async () => {
    window.addEventListener('load', function () {
        const el = document.getElementsByClassName('cpyBtn');

        if (0 === el.length) {
            return;
        }
    
        el[0].addEventListener("click", copy);
  
        function copy() {
            var btn = document.getElementsByClassName('cpyBtn');
            if(btn) {
                var copySuccess = document.getElementsByClassName('copyMessage');
                var copiedText = btn[0].dataset.clipboardText;
                navigator.clipboard.writeText(copiedText);
                copySuccess[0].getElementsByTagName("h4")[0].innerHTML = 'Link Copied!';

                
                    // .5 seconds later, add class
                    setTimeout(function(){
                        copySuccess[0].getElementsByTagName("h4")[0].classList.add("fadeOut");
                    }, 500);

                    // .5 seconds later, remove class and text
                    setTimeout(function(){
                        copySuccess[0].getElementsByTagName("h4")[0].classList.remove("fadeOut");
                        copySuccess[0].getElementsByTagName("h4")[0].innerHTML = '';
                    }, 1000);
                
            }

        }
    });
    
}
