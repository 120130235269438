import * as $ from 'jquery';

export const tabs = () => {
    $(document).ready(function() {
        const $tabs = $("#tabs li");

        if (0 === $tabs.length) {
            return;
        }

        $tabs.eq(0).addClass('active');

        const $content = $tabs.closest('.column').next();
        $content.find('.tab-content').not(':first').hide();

        $tabs.click(function (e) {
            e.preventDefault();

            const $target = $(e.currentTarget);

            if ($target.hasClass('active')) {
                return;
            }

            $tabs.filter('.active').removeClass('active');
            $target.addClass('active');
            $content.find('.tab-content:visible').fadeOut(400, function () {
                $($target.find('>a').attr('href')).fadeIn();
            });
        });
    });
};
