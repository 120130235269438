import * as $ from 'jquery';

export const nav = () => {
    var closeMenu = null;

    $(".parent").bind('mouseenter',function(){
        clearTimeout(closeMenu);
        $(this).siblings().each(function() { 
            $(this).removeClass("opened");
            $(this).find(".subnav").stop().slideUp(100);
        });
        $(this).addClass("opened");
        $(this).find(".subnav").slideDown(300);
    });
    $('.parent').bind('mouseleave',function(){ 
        var dd=$(this);
        var ddm=$(this).find(".subnav");
        closeMenu=setTimeout(function(){ 
            ddm.slideUp(100);
            dd.removeClass("opened"); 
        },333);
    });

    //Mobile Triggers
    $('.mobileTrigger h4').on('click', function(e) {
        e.preventDefault();
        $('#mobileNav').toggleClass('is-active');
    });
    //Close Triggers
    $('.mobileClose').on('click', function(e) {
        e.preventDefault();
        $('#mobileNav').removeClass('is-active');
    });

    // Slide in/out subnav menus
    $(".hasChild a").on('click', function(e) {
        e.preventDefault();

        var clickedIndex = $(this).closest('li').index();
        var id = $(this).attr('data-id');

        //Adds animation delay
        $('.navWrapper ul').each(function(index, item) {
            $(item).find('li').each(function(subIndex, subItem) {
                // $(subItem).css('animation-delay', parseInt(Math.abs(clickedIndex - subIndex) * 60) + 'ms');
                subItem.style.animationDelay = parseInt(Math.abs(clickedIndex - subIndex) * 60) + 'ms';
            });
        });

        $(e.target).closest('ul').find('li').removeClass('animate-inFromLeft').addClass('animate-outToLeft');

        $('.navWrapper').find('#' + id).addClass('subActive').find('li').removeClass('animate-outToRight').addClass('subActive animate-inFromRight');
        
        $('.nav.subnav').hide();
        e.preventDefault();
        var $subMenu = $(this).data('id');
        console.log($subMenu);
        $('#'+$subMenu).show();
    });

    $('.navWrapper .back a').click(function(e) {
        e.preventDefault();
        
        $(this).closest('ul').removeClass('subActive');
        $('.animate-outToLeft').removeClass('animate-outToLeft').addClass('animate-inFromLeft');
        $('.animate-inFromRight').removeClass('animate-inFromRight').addClass('animate-outToRight');
    });
};
