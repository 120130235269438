import Flickity from 'flickity';

export const carousels = () => {
    const elem = document.querySelector('.carousel');
    if (elem) {
        new Flickity(elem, {
            autoPlay: true,
            cellAlign: 'left',
            prevNextButtons: false,
            contain: true,
            wrapAround: true
        });
    }

    const gallery = document.querySelector('.galleryCarousel');
    if (gallery) {
        new Flickity(gallery, {
            // options
            fullscreen: true,
            lazyLoad: 1,
            wrapAround: true
        });
    }

};
