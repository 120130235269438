require('@fancyapps/fancybox');
require('!style-loader!css-loader!@fancyapps/fancybox/dist/jquery.fancybox.css');

import { tabs } from "./components/tabs";
import { nav } from "./components/nav";
import { accordion } from "./components/accordion";
import { carousels } from "./components/carousels";
import { copy } from "./components/copy";
import AOS from 'aos';

tabs();
nav();
accordion();
carousels();
copy();
AOS.init();